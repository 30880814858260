@import "../styles/settings/variables";

.thinkInsideTheBox {
  a::after {
    display: none;
  }
  font-size: 2em;
  position: relative;
  padding: 0;
  height: calc(100vh - 200px);
  // margin-top: -$menuHeight;

  @media screen and(min-width:$screen-lg) {
    padding: 1em;
    padding-bottom: 3em;
    // height: calc(100vh - 100px);
    height: calc(100vh - 250px);
  }

  @media screen and(max-height:$screen-sm) {
    height: auto;
    min-height: 100vh;
    padding-bottom: 3em;
  }

  @media screen and(max-height:$screen-md) {
    height: auto;
  }

  &__video {
    width: 100%;
    object-fit: cover;
    min-height: 100%;
  }

  &__content {
    position: relative;
    top: 0;
    flex-direction: column-reverse;

    @media screen and(min-width:$screen-md) {
      top: 10%;
      padding: 0 20px;
    }

    @media screen and(min-width:$screen-lg) {
      align-content: center;
      flex-direction: row;
      top: 55%;
      // top: 40%;
      transform: translateY(-50%);
      position: absolute;
    }

    @media screen and(max-height:$screen-md) {
      position: relative;
      top: 0;
      transform: translateY(0%);
    }
  }

  &__aboutTitle {
    $aboutTitleFontSize: calc(max(3vh, 10vw));
    font-size: calc(min(var($aboutTitleFontSize), 3em));
    line-height: calc(min(var($aboutTitleFontSize), 3em));
    @media screen and(min-width:$screen-lg) {
      padding-left: 0;
    }
  }

  &__description {
    font-family: $third-font-family;
    font-size: calc(min(max(3vh, 3vw), 1.5rem));
    line-height: calc(min(max(3vh, 3vw), 2rem));
    font-weight: $weight-light;
    padding: 15px 0 30px 0;

    @media screen and(min-width:$screen-md) {
      font-size: calc(min(max(1.5vh, 1.5vw), 2rem));
      line-height: calc(min(max(2vh, 2vw), 2.3rem));
      padding: 20px 0 0 0;
    }

    @media screen and(min-width:$screen-xl) {
      padding: 20px 0 0 0;
    }

    /* 1792x828px at 326ppi */
    @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
      font-size: 20px;
    }
  }

  &__descriptionWin {
    @media screen and(min-width:$screen-lg) {
      padding: 25px 0 0 0;
    }

    // @media screen and(min-width:$screen-md) {
    //   padding: 30px 0 30px 0;
    // }
  }
}

br {
  content: "";
  margin: 2em;
  display: block;
  margin-bottom: -30px;
  @media screen and(min-width:$screen-md) {
    margin-bottom: -20px;
  }
}

strong {
  font-weight: bold;
}

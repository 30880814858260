@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/vendor/rfs";
@import "~bootstrap/scss/mixins/border-radius";
@import "~bootstrap/scss/input-group";

// Colours
$black: #000000;
$white: #ffffff;
$ltGrey: #c2c2c2;
$dkGrey: #474747;
$red: #ff0037;
$lightRed: #ff0037;

$green: #39cc74;
$linkBlue: #2699fb;

// Colour Roles
$primary-color: $black;
$secondary-color: $white;
$tertiary-color: $dkGrey;
$quaternary-color: $red;

//Menu
$menuHeight: 130px;

//Viewport
$viewableArea: calc(100vh - #{$menuHeight});
$mobileViewableArea: calc(100vh - 51px);

// Colour Roles
$primary-color: $black;

// Screen Sizes
$screen-xl: map-get($grid-breakpoints, xl); // ~1200px
$screen-lg: map-get($grid-breakpoints, lg); // ~992px
$screen-md: map-get($grid-breakpoints, md); // ~768px
$screen-sm: map-get($grid-breakpoints, sm); // ~576px
$screen-xs: map-get($grid-breakpoints, xs); // 0

// Screen Sizes Max
$screen-xl-max: $screen-xl - 1;
$screen-lg-max: $screen-lg - 1;
$screen-md-max: $screen-md - 1;
$screen-sm-max: $screen-sm - 1;

// Sizes
$font-size-base: 1.6rem;

$font-size-initial: 9rem; //90px;
$font-size-h1: 4.4rem; // 44px
$font-size-h2: 3.6rem; // 36px
$font-size-h3: 3rem; // 30px
$font-size-h4: 2.8rem; // 28px
$font-size-h5: 2.6rem; // 26px
$font-size-h6: 2.4rem; // 24px
$font-size-h7: 2rem; // 20px
$font-size-h8: 1.8rem; // 18px

$font-size-subtitle: 1.5rem; // 15px
$font-size-ads: 1.2rem; //12px

// Letter spacings
$font-size-h1-word-spacing: 6rem;
$font-size-h4-letter-spacing: 0.1rem;

//line-height
$line-height-title: 0.9;
$line-height-member-btn: 1.8;

// Fonts

$weight-extra-light: 100;
$weight-light: 300;
$weight-normal: 400;
$weight-bold: 600;
$weight-extra-bold: 800;

$primary-font-family: "TradeGothic";
$primary-font-size: 1.6rem;
$primary-weight: $weight-normal;
$primary-letter-spacing: 0rem;
$primary-word-spacing: 0rem;

$secundary-font-family: "proximanova-thin";
$secundary-regular-font-family: "proximanova-regular";
$secundary-font-size: 1.6rem;
$secundary-weight: $weight-normal;
$secundary-letter-spacing: 0rem;
$secundary-word-spacing: 0rem;

$third-font-family: "trade-gothic-next";
$third-font-size: 1.6rem;
$third-weight: $weight-normal;
$third-letter-spacing: 0rem;
$third-word-spacing: 0rem;

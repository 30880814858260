// ORDER
//-------
// Normalize
// Variables
// Typography
// Mixins
// Reset
//-------

@import "./settings/fonts";
@import "./settings/variables";
@import "../components/HomePage//styles/settings/fonts";
@import "../components/HomePage//styles/settings/variables";

:global {
  @import "./tools/tools.scss";
  @import "./utilities/utilities.scss";
  @import "./base/base.scss";
  @import "../components/HomePage//styles/tools/tools.scss";
  @import "../components/HomePage//styles/utilities/utilities.scss";
  // @import "../components/HomePage//styles/base/base.scss";
}

@import "./settings/typography";
@import "../components/HomePage//styles/settings/typography";

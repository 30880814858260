@import "../styles/settings/variables";

.wrapper {
  // margin-top: -130px;
  @media screen and (max-width: $screen-lg) {
    margin-top: 30px;
  }
}

.template {
  display: grid;
  width: 90%;
  margin: 0px auto 0 auto;

  grid-template:
    [row1] "a b" 20vw
    [row2] "a c" 20vw
    / 45vw 45vw;
  @media screen and (max-width: $screen-lg) {
    margin-top: 30px;
    padding-bottom: 150px;
    grid-template:
      [row1] "a" 50vh
      [row2] "b" 50vh
      [row3] "c" 50vh
      / 100%;
  }
}

.link {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.link::after {
  display: none;
}

.a {
  grid-area: a;
}

.b {
  grid-area: b;
}

.c {
  grid-area: c;
}

.a > * > .artistShowcase {
  transform: translateY(100%);
  @media screen and (max-width: $screen-lg) {
    // transform: translateY(78%);
    bottom: 17%;
  }
}

.b > * > .artistShowcase,
.c > * > .artistShowcase {
  transform: translateY(90%);
}

.artistShowcase {
  position: absolute;
  left: 5%;
  bottom: 10%;
  transition: all;
  transition-duration: 0.5s;
}

.artistShowcaseCategory {
  $fontSize: calc(min(4vh, 2vw));
  text-transform: uppercase;
  font-size: $fontSize;
  line-height: $fontSize;

  @media screen and (max-width: $screen-lg) {
    $fontSize: calc(min(6vh, 5vw));
    font-size: $fontSize;
    line-height: $fontSize;
  }
}

.artistShowcaseName {
  $fontSize: calc(min(7vh, 3vw));
  text-transform: uppercase;
  font-size: $fontSize;
  line-height: $fontSize;

  @media screen and (max-width: $screen-lg) {
    $fontSize: calc(min(7vh, 10vw));
    font-size: $fontSize;
    line-height: $fontSize;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all;
  transition-duration: 1s;
}

.description {
  max-width: 80%;
  opacity: 0;
  transition: all;
  transition-duration: 0.5s;
  margin-bottom: 10px;
}

.artistShowcaseLink {
  transition: all;
  transition-duration: 0.5s;
  opacity: 0;
  $fontSize: calc(min(4vh, 2vw));
  $paddingTop: calc(min(4vh, 2vw));
  $paddingBottom: calc(min(4vh, 1.5vw));
  $paddingX: calc(min(2vh, 1vw));
  text-transform: uppercase;
  border: 3px solid white;
  width: fit-content;
  line-height: 0;
  padding: $paddingTop $paddingX $paddingBottom $paddingX;
  font-size: $fontSize;

  @media screen and (max-width: $screen-lg) {
    $fontSize: calc(min(6vh, 5vw));
    $paddingTop: calc(min(6vh, 3vw));
    $paddingBottom: calc(min(6vh, 1.5vw));
    $paddingX: calc(min(3vh, 1vw));
    font-size: $fontSize;
    padding: $paddingTop $paddingX $paddingBottom $paddingX;
  }
}

@media screen and (min-width: $screen-lg) {
  .cover {
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
    position: absolute;
    transition: all;
    transition-duration: 1s;
  }

  .showcase:hover .cover {
    opacity: 0.6;
    padding-bottom: 5px;
  }

  .showcase:hover .description {
    opacity: 1;
  }

  .showcase:hover .artistShowcaseLink {
    opacity: 1;
  }

  .artistShowcaseLink:hover {
    background-color: white;
    color: black !important;
  }

  .showcase:hover .image {
    transform: scale(1.2);
  }

  .showcase:hover .artistShowcase {
    transform: translateY(0);
  }
}

//ADD CUSTOM FONT FACES HER//ADD CUSTOM FONT FACES HERE
@import url("https://p.typekit.net/p.css?s=1&k=ttu4ais&ht=tk&f=39687&a=2023117&app=typekit&e=css");

@font-face {
  font-family: "TradeGothic";
  src: url(../fonts/TradeGothicLTStd-BdCn20.otf) format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "proximanova-regular";
  src: url(../fonts/proximanova-regular.otf) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "proximanova-thin";
  src: url(../fonts/proximanova-thin.otf) format("opentype");
  font-display: swap;
}

// @font-face{
//   font-family:"trade-gothic-lt-std";
//   src: url(../fonts/TradeGothicLTStd.otf) format("woff"),
//   url(../fonts/TradeGothicLTStd.otf) format("opentype"),
//   url(../fonts/TradeGothicLTStd.otf) format("truetype");
//   font-display: swap
// }

@font-face {
  font-family: "trade-gothic-next";
  src: url("https://use.typekit.net/af/fa3304/00000000000000003b9b2105/27/l?subset_id=2&fvd=n3&v=3") format("woff2"),
    url("https://use.typekit.net/af/fa3304/00000000000000003b9b2105/27/d?subset_id=2&fvd=n3&v=3") format("woff"),
    url("https://use.typekit.net/af/fa3304/00000000000000003b9b2105/27/a?subset_id=2&fvd=n3&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "trade-gothic-next";
  src: url("https://use.typekit.net/af/0f438f/00000000000000003b9b2107/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/0f438f/00000000000000003b9b2107/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/0f438f/00000000000000003b9b2107/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

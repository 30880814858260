html {
  // This defines what 1rem is
  font-size: 62.5%; //1rem = 10px; 10px/16px = 62.5%
}

h1 {
  font-size: $font-size-initial;
  font-family: $primary-font-family;
}

h2 {
  font-size: 3em;
  line-height: 58px;
}

a {
  color: $white;
  text-decoration: none;
}

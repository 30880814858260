@import "../../styles/settings/variables";
@import "../../styles/utilities/mixins";

.deselect {
  color: darkgrey !important;
}

.PhotoUsers {
  &__List {
    padding-top: 13rem;
    // padding-left: 1rem;
    padding-left: 6px;
    // border: 1px solid blue;

    @media screen and(min-width:$screen-lg) {
      // padding-left: 2rem;
      padding-left: 15px;
      padding-right: 4rem;
    }
  }

  &__header {
    padding: 0;
    display: block;
    position: relative;
    // border: 1px solid salmon;

    @media screen and(min-width:$screen-lg) {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
    }
  }

  &__title {
    text-transform: uppercase;
    line-height: $line-height-t0 + 0.25;
    margin-bottom: 4rem;

    &:after {
      margin-top: 7px;
      position: absolute;
      content: "";
      background: $secondary-color;
      width: 40px;
      height: 4px;
      display: block;
    }
  }

  &__list {
    margin: 0;
    list-style: none;
    height: calc(100vh - 35rem);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: inherit;
    flex-wrap: inherit;
    // overflow-x: hidden;
    // overflow-y: auto;
    height: 100%;
    margin-left: 0;
    padding-top: 1rem;

    @media screen and(min-width:$screen-lg) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      overflow-x: auto;
      overflow-y: hidden;
      height: calc(100vh - 38rem);
      padding-top: 1rem;
    }

    @media screen and(max-height:$screen-lg) {
      height: auto;
      -ms-flex-wrap: inherit;
      flex-wrap: inherit;
    }
  }

  &__listItemLink {
    &:after {
      content: none;
    }
  }

  &__listItemTitle {
    text-transform: uppercase;
    line-height: $line-height-t2 + 0.5;
    // margin: 7px 50px 5px 0px;
    font-size: $font-size-h2-a - 7;
    margin-right: 0;
    @media screen and(min-width:$screen-md) {
      // line-height: $line-height-t2 + .15;
      font-size: $font-size-h2-a - 6;
    }

    @media screen and(min-width:$screen-lg) {
      margin-right: 5rem;
    }

    @media screen and(min-width:$screen-xl) {
      // line-height: $line-height-t2 - .1;
      font-size: $font-size-h2-a - 3;
      margin: 0px 50px 0 0px;
      line-height: 1;
    }

    h2 {
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 0.5s ease-out;
      -ms-transition: all 0.5s ease-out;
      -o-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
    }
  }

  //////////// change column style on limit photousers
  &__photoUserColumn {
    height: auto;
    -ms-flex-wrap: inherit;
    flex-wrap: inherit;
    border: 1px solid red;
  }
}

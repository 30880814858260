html {
  // This defines what 1rem is
  font-size: 62.5%; //1rem = 10px; 10px/16px = 62.5%
}

*:focus {
  text-decoration: none;
}

p {
  font-size: $font-size-base;
  font-family: $secondary-font-family;
  color: $secondary-color;
  line-height: $line-height-base;
}

h2,
.h2 {
  font-size: $font-size-h2-a - 6;
  font-family: $secondary-font-family;
  font-weight: $weight-bold;
  color: $secondary-color !important;
  line-height: $line-height-title;

  @media screen and(min-width:$screen-md) {
    font-size: $font-size-h2-a - 3;
  }

  @media screen and(min-width:$screen-xl) {
    font-size: $font-size-h2-a;
  }
}

h3,
.h3 {
  font-size: $font-size-h3-a;
  font-family: $secondary-font-family;
  font-weight: $weight-bold;
  color: $secondary-color;
  line-height: $line-height-title;
}

h4 {
  font-size: $font-size-h4-a;
  font-family: $secondary-font-family;
  font-weight: $weight-bold;
  color: $secondary-color;
  line-height: $line-height-title;

  // color:$white;
  // @media screen and (min-width:$screen-sm){
  //     font-size: $font-size-h4;

  // }
}

h5 {
  font-size: $font-size-h5-a;
  font-family: $secondary-font-family;
  font-weight: $weight-bold;
  color: $secondary-color;
  line-height: $line-height-title;
}

h6 {
  font-size: $font-size-h6-a;
  font-family: $secondary-font-family;
  font-weight: $weight-bold;
  color: $secondary-color;
  text-transform: uppercase;
  line-height: $font-size-h6-a;
  white-space: nowrap;
  padding-top: 7px;
}

button {
  background: none;
  border: none;
  outline: none;
  &:focus {
    outline: none;
  }
}

a {
  text-decoration: none;
  font-family: $secondary-font-family;
  color: $secondary-color;
  cursor: pointer;
  display: inline-block;

  &:after {
    content: "";
    display: block;
    border-bottom: solid 5px $secondary-color;
  }

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  &:hover:after {
    -webkit-animation: borderBottomMove 1590ms infinite; /* Safari 4+ */
    -moz-animation: borderBottomMove 1590ms infinite; /* Fx 5+ */
    -o-animation: borderBottomMove 1590ms infinite; /* Opera 12+ */
    animation: borderBottomMove 1590ms infinite; /* IE 10+, Fx 29+ */
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
figcaption {
  font-size: 1rem;
  font-family: $primary-font-family;
  text-transform: uppercase;
}

// /* Select */
//  select {
//     display: inline;
//     // position: absolute;
//     overflow-x: hidden;
//     overflow-y: hidden;
//     // font-family: inherit;
//     // font-size: inherit;
//     // font-style: inherit;
//     // font-weight: inherit;
//     color: inherit;
//     background-color: transparent;
//     margin: 0;
//     padding: 0;
//     width: 0;
//     height: 0;
//     opacity: 0;
//     max-width: 100%;
//     box-shadow: none;
//     outline-style: none;
//     border-style: none;
//     appearance: none
// }

// /* Hover */
//  select:hover,
// select:focus {
//     cursor: pointer;
//     color: #5F6263;
//     outline-style: none
// }

// /* Click */
// select:active {
//     color: #373A3B
// }

// /* Operating system-generated, only the typo can be styled */
//  option {
//     font-family: inherit;
//     font-size: inherit;
//     font-style: inherit;
//     font-weight: inherit;
//     color: inherit;
//     background-color: transparent
// }
.option {
  // padding: 0 30px 0 10px;
  // min-height: 40px;
  // display: flex;
  // align-items: center;
  // background: #333;
  // border-top: #222 solid 1px;
  // position: absolute;
  // top: 0;
  // width: 100%;
  // pointer-events: none;
  // order: 2;
  // z-index: 1;
  // transition: background .4s ease-in-out;
  // box-sizing: border-box;
  // overflow: hidden;
  // white-space: nowrap;
}

// .option:hover {
//     background: #666;
// }

// .select:focus .option {
//     position: relative;
//     pointer-events: all;
// }

// input {
//     opacity: 0;
//     position: absolute;
//     left: -99999px;
// }

// input:checked+label {
//     order: 1;
//     z-index: 2;
//     background: #666;
//     border-top: none;
//     position: relative;
// }

// input:checked+label:after {
//     content: '';
//     width: 0;
//     height: 0;
//     border-left: 5px solid transparent;
//     border-right: 5px solid transparent;
//     border-top: 5px solid white;
//     position: absolute;
//     right: 10px;
//     top: calc(50% - 2.5px);
//     pointer-events: none;
//     z-index: 3;
// }

// input:checked+label:before {
//     position: absolute;
//     right: 0;
//     height: 40px;
//     width: 40px;
//     content: '';
//     background: #666;
// }
